import { cva, type VariantProps } from 'class-variance-authority';
import { twMerge } from 'tailwind-merge';

const frame = cva(
  [
    'relative h-full',
    '*:absolute',
    '*:top-0',
    '*:bottom-0',
    '*:left-0',
    '*:right-0',
    'overflow-hidden',
    '*:flex',
    '*:w-full',
    '*:h-full',
    '[&>img]:items-center',
    '[&>img]:justify-center',
  ],
  {
    variants: {
      fit: {
        cover: '*:object-cover',
        contain: '*:!object-contain',
        'scale-down': '*:!object-scale-down',
      },
      justify: {
        start: '*:justify-start',
        center: '*:justify-center',
        end: '*:justify-end',
        between: '*:justify-between',
      },
      align: {
        start: '*:items-start',
        center: '*:items-center',
        end: '*:items-end',
        stretch: '*:items-stretch',
      },
      ratio: {
        auto: 'aspect-auto',
        square: 'aspect-square',
        video: 'aspect-video',
      },
    },
    // compoundVariants: [{ gap: 'md', align: 'start', class: 'uppercase' }],
    defaultVariants: {
      fit: 'cover',
      justify: 'center',
      align: 'center',
      ratio: 'auto',
    },
  },
);

export interface FrameProps
  extends React.HTMLAttributes<HTMLElement>,
    VariantProps<typeof frame> {}

export const Frame: React.FC<FrameProps> = ({
  className,
  ratio,
  justify,
  align,
  fit,
  ...props
}) => (
  <div
    className={twMerge(frame({ ratio, fit, justify, align, className }))}
    {...props}
  />
);
