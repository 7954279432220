import { cva, type VariantProps } from 'class-variance-authority';
import { twMerge } from 'tailwind-merge';

const stack = cva('flex flex-col', {
  variants: {
    gap: {
      none: ['gap-0'],
      xs: ['gap-0.5'],
      sm: ['gap-1'],
      md: ['gap-2'],
      lg: ['gap-4'],
      xl: ['gap-8'],
      xxl: ['gap-16'],
    },
    justify: {
      start: 'justify-start',
      center: 'justify-center',
      end: 'justify-end',
      between: 'justify-between',
    },
    align: {
      start: 'items-start',
      center: 'items-center',
      end: 'items-end',
      stretch: 'items-stretch',
    },
  },
  compoundVariants: [{ align: 'center', class: 'text-center' }],
  defaultVariants: {
    gap: 'md',
    justify: 'start',
    align: 'start',
  },
});

export interface StackProps
  extends React.HTMLAttributes<HTMLElement>,
    VariantProps<typeof stack> {}

export const Stack: React.FC<StackProps> = ({
  className,
  gap,
  justify,
  align,
  ...props
}) => (
  <div
    className={twMerge(stack({ gap, justify, align, className }))}
    {...props}
  />
);
