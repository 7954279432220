import { useFormAction, useNavigation } from '@remix-run/react';
import { clsx, type ClassValue } from 'clsx';
import { useEffect, useMemo, useRef, useState } from 'react';
import { orderBy } from 'natural-orderby';
import { useSpinDelay } from 'spin-delay';
import { extendTailwindMerge } from 'tailwind-merge';
import { extendedTheme } from './extended-theme.ts';

export function getErrorMessage(error: unknown) {
  if (typeof error === 'string') return error;
  if (
    error &&
    typeof error === 'object' &&
    'message' in error &&
    typeof error.message === 'string'
  ) {
    return error.message;
  }
  console.error('Unable to get error message for error', error);
  return 'Unknown Error';
}

function formatColors() {
  const colors = [];
  for (const [key, color] of Object.entries(extendedTheme.colors)) {
    if (typeof color === 'string') {
      colors.push(key);
    } else {
      const colorGroup = Object.keys(color).map((subKey) =>
        subKey === 'DEFAULT' ? '' : subKey,
      );
      colors.push({ [key]: colorGroup });
    }
  }
  return colors;
}

const customTwMerge = extendTailwindMerge<string, string>({
  extend: {
    theme: {
      colors: formatColors(),
      borderRadius: Object.keys(extendedTheme.borderRadius),
    },
    classGroups: {
      'font-size': [
        {
          text: Object.keys(extendedTheme.fontSize),
        },
      ],
    },
  },
});

export function cn(...inputs: ClassValue[]) {
  return customTwMerge(clsx(inputs));
}

export function getDomainUrl(request: Request) {
  const host =
    request.headers.get('X-Forwarded-Host') ??
    request.headers.get('host') ??
    new URL(request.url).host;
  const protocol = host.includes('localhost') ? 'http' : 'https';
  return `${protocol}://${host}`;
}

export function getReferrerRoute(request: Request) {
  // spelling errors and whatever makes this annoyingly inconsistent
  // in my own testing, `referer` returned the right value, but 🤷‍♂️
  const referrer =
    request.headers.get('referer') ??
    request.headers.get('referrer') ??
    request.referrer;
  const domain = getDomainUrl(request);
  if (referrer?.startsWith(domain)) {
    return referrer.slice(domain.length);
  } else {
    return '/';
  }
}

export function sortProductsByProductType(data: ProductProps[]) {
  const correctOrderOfProductTypes = [
    'dc-dc-converters',
    'dc-dc-converters-with-integral-emi-filters',
    'emi-filters',
    'point-of-load-dc-dc-converters',
    'accessory-modules',
    'inrush-current-limiters',
    'transient-suppression-with-emi-filters',
    'power-conditioning-module',
    'bus-converter-module',
    'accessories',
    'thermal-pad',
    'lead-extender-series',
  ];

  const productTypeOrder = correctOrderOfProductTypes.reduce<{
    [key: string]: number;
  }>((acc, productType, index) => {
    acc[productType] = index;
    return acc;
  }, {});

  return orderBy(
    data,
    [
      (a) =>
        a.productType && a.productType.length > 0
          ? productTypeOrder[a.productType[0].slug]
          : undefined,
      (a) => a.outputPower,
    ],
    ['asc', 'desc'],
  );
}

/**
 * Merge multiple headers objects into one (uses set so headers are overridden)
 */
export function mergeHeaders(
  ...headers: Array<ResponseInit['headers'] | null | undefined>
) {
  const merged = new Headers();
  for (const header of headers) {
    if (!header) continue;
    for (const [key, value] of new Headers(header).entries()) {
      merged.set(key, value);
    }
  }
  return merged;
}

/**
 * Combine multiple header objects into one (uses append so headers are not overridden)
 */
export function combineHeaders(
  ...headers: Array<ResponseInit['headers'] | null | undefined>
) {
  const combined = new Headers();
  for (const header of headers) {
    if (!header) continue;
    for (const [key, value] of new Headers(header).entries()) {
      combined.append(key, value);
    }
  }
  return combined;
}

/**
 * Combine multiple response init objects into one (uses combineHeaders)
 */
export function combineResponseInits(
  ...responseInits: Array<ResponseInit | null | undefined>
) {
  let combined: ResponseInit = {};
  for (const responseInit of responseInits) {
    combined = {
      ...responseInit,
      headers: combineHeaders(combined.headers, responseInit?.headers),
    };
  }
  return combined;
}

/**
 * Returns true if the current navigation is submitting the current route's
 * form. Defaults to the current route's form action and method POST.
 *
 * Defaults state to 'non-idle'
 *
 * NOTE: the default formAction will include query params, but the
 * navigation.formAction will not, so don't use the default formAction if you
 * want to know if a form is submitting without specific query params.
 */
export function useIsPending({
  formAction,
  formMethod = 'POST',
  state = 'non-idle',
}: {
  formAction?: string;
  formMethod?: 'POST' | 'GET' | 'PUT' | 'PATCH' | 'DELETE';
  state?: 'submitting' | 'loading' | 'non-idle';
} = {}) {
  const contextualFormAction = useFormAction();
  const navigation = useNavigation();
  const isPendingState =
    state === 'non-idle'
      ? navigation.state !== 'idle'
      : navigation.state === state;
  return (
    isPendingState &&
    navigation.formAction === (formAction ?? contextualFormAction) &&
    navigation.formMethod === formMethod
  );
}

/**
 * This combines useSpinDelay (from https://npm.im/spin-delay) and useIsPending
 * from our own utilities to give you a nice way to show a loading spinner for
 * a minimum amount of time, even if the request finishes right after the delay.
 *
 * This avoids a flash of loading state regardless of how fast or slow the
 * request is.
 */
export function useDelayedIsPending({
  formAction,
  formMethod,
  delay = 400,
  minDuration = 300,
}: Parameters<typeof useIsPending>[0] &
  Parameters<typeof useSpinDelay>[1] = {}) {
  const isPending = useIsPending({ formAction, formMethod });
  const delayedIsPending = useSpinDelay(isPending, {
    delay,
    minDuration,
  });
  return delayedIsPending;
}

function callAll<Args extends Array<unknown>>(
  ...fns: Array<((...args: Args) => unknown) | undefined>
) {
  return (...args: Args) => fns.forEach((fn) => fn?.(...args));
}

/**
 * Use this hook with a button and it will make it so the first click sets a
 * `doubleCheck` state to true, and the second click will actually trigger the
 * `onClick` handler. This allows you to have a button that can be like a
 * "are you sure?" experience for the user before doing destructive operations.
 */
export function useDoubleCheck() {
  const [doubleCheck, setDoubleCheck] = useState(false);

  function getButtonProps(
    props?: React.ButtonHTMLAttributes<HTMLButtonElement>,
  ) {
    const onBlur: React.ButtonHTMLAttributes<HTMLButtonElement>['onBlur'] =
      () => setDoubleCheck(false);

    const onClick: React.ButtonHTMLAttributes<HTMLButtonElement>['onClick'] =
      doubleCheck
        ? undefined
        : (e) => {
            e.preventDefault();
            setDoubleCheck(true);
          };

    const onKeyUp: React.ButtonHTMLAttributes<HTMLButtonElement>['onKeyUp'] = (
      e,
    ) => {
      if (e.key === 'Escape') {
        setDoubleCheck(false);
      }
    };

    return {
      ...props,
      onBlur: callAll(onBlur, props?.onBlur),
      onClick: callAll(onClick, props?.onClick),
      onKeyUp: callAll(onKeyUp, props?.onKeyUp),
    };
  }

  return { doubleCheck, getButtonProps };
}

/**
 * Simple debounce implementation
 */
function debounce<Callback extends (...args: Parameters<Callback>) => void>(
  fn: Callback,
  delay: number,
) {
  let timer: ReturnType<typeof setTimeout> | null = null;
  return (...args: Parameters<Callback>) => {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      fn(...args);
    }, delay);
  };
}

/**
 * Debounce a callback function
 */
export function useDebounce<
  Callback extends (...args: Parameters<Callback>) => ReturnType<Callback>,
>(callback: Callback, delay: number) {
  const callbackRef = useRef(callback);
  useEffect(() => {
    callbackRef.current = callback;
  });
  return useMemo(
    () =>
      debounce(
        (...args: Parameters<Callback>) => callbackRef.current(...args),
        delay,
      ),
    [delay],
  );
}

export const useScreenSize = () => {
  if (typeof document === 'undefined') return;

  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  let sm = false,
    md = false,
    lg = false;

  if (screenSize.width >= 1024) {
    lg = true;
  }

  if (screenSize.width >= 768) {
    md = true;
  }

  if (screenSize.width >= 425) {
    sm = true;
  }

  return { sm, md, lg };
};

export const getImageUrl = (
  screenSize: { lg: any; md: any; sm: any; xs: any } | undefined,
  img:
    | {
        url: any;
        srcset?: string;
        urlLg?: any;
        urlMd?: any;
        urlSm?: any;
        urlXs?: any;
      }
    | undefined,
) => {
  if (img && screenSize) {
    if (screenSize.xs) return img.urlXs;
    else if (screenSize.sm) return img.urlSm;
    else if (screenSize.md) return img.urlMd;
    else return img.url;
  }

  return img ? img.url : '';
};

export const getImageItemUrlByHandle = (data: any, handle: string): string => {
  if (!data) return '';

  const collection = data.find(
    (image: { imageHandle: string }) => image.imageHandle == handle,
  );
  const image = collection && collection.image ? collection.image[0] : '';

  return image ? image.url : '';
};

export const getShortMonths = (month: EventListItem['month']) => {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const monthIndex = monthNames.indexOf(month);

  if (monthIndex === -1) {
    throw new Error('Invalid month name');
  }

  // const shortMonth = month.slice(0, 3);
  // const nextMonthIndex = (monthIndex + 1) % 12;
  // const shortNextMonth = monthNames[nextMonthIndex].slice(0, 3);

  const currentYear = new Date().getFullYear();
  const date = new Date(currentYear, monthIndex, 1);

  const options: Intl.DateTimeFormatOptions = { month: 'short' };
  const shortMonth = date.toLocaleString('en-US', options);

  const nextDate = new Date(currentYear, (monthIndex + 1) % 12, 1);
  const shortNextMonth = nextDate.toLocaleString('en-US', options);

  return {
    shortMonth,
    shortNextMonth,
  };
};

type Digit = '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9';
type OneDigit = `${Digit}`;
type TwoDigits = `${Digit}${Digit}`;
type OneOrTwoDigits = OneDigit | TwoDigits;
type ValidVersion = `${OneOrTwoDigits}.${OneOrTwoDigits}`;

export const getSafariCompatibility = (safariVersion: ValidVersion) => {
  const userAgent = navigator.userAgent;
  let isSafariBrowser = false;
  let isNotCompatible = false;

  const isAppleDevice = /ipad|iphone|macintosh/i.test(userAgent);

  if (isAppleDevice) {
    isSafariBrowser = /^((?!chrome|android).)*safari/i.test(userAgent);
    const versionMatch = userAgent.match(/version\/([0-9]+(\.[0-9]+)?)/i);

    if (isSafariBrowser && versionMatch) {
      const currentVersion = versionMatch[1];
      const currentVersionParts = currentVersion.split('.').map(Number);
      const safariVersionParts = safariVersion.split('.').map(Number);

      isNotCompatible =
        currentVersionParts[0] < safariVersionParts[0] ||
        (currentVersionParts[0] === safariVersionParts[0] &&
          currentVersionParts[1] < safariVersionParts[1]);
    }
    return !isNotCompatible;
  }
  return true;
};
