import { cva, type VariantProps } from 'class-variance-authority';
import React from 'react';
import { twMerge } from 'tailwind-merge';
import { cn } from '#app/utils/misc.tsx';

const container = cva('', {
  variants: {
    gap: {
      none: 'gap-0',
      xs: ['gap-0.5'],
      sm: ['gap-1'],
      md: ['gap-2'],
      lg: ['gap-4'],
      xl: ['gap-8'],
      xxl: ['gap-16'],
    },
    maxWidth: {
      none: '',
      sm: 'max-inline-size-sm',
    },
  },
  // compoundVariants: [{ gap: 'md', align: 'start', class: 'uppercase' }],
  defaultVariants: {
    gap: 'none',
    maxWidth: 'none',
  },
});

interface ContainerProps
  extends React.HTMLAttributes<HTMLElement>,
    VariantProps<typeof container> {}

const Container: React.FC<ContainerProps> = ({
  className,
  gap,
  maxWidth,
  ...props
}) => (
  <div
    className={twMerge(container({ gap, maxWidth, className }))}
    {...props}
  />
);

Container.displayName = 'Container';

const ContainerContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => {
  return (
    <div
      ref={ref}
      role="group"
      className={cn('container', className)}
      {...props}
    />
  );
});

ContainerContent.displayName = 'ContainerContent';

export { type ContainerProps, Container, ContainerContent };
